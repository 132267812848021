<template>
  <div class="flex flex-col space-y-10">
    <h3 class="text-center">
      {{ $t('Asset requests.Asset specs sharing') }}
    </h3>
    <div class="flex flex-col space-y-2 text-center">
      <CInput
        v-model="expire"
        :placeholder="`${$t('Asset requests.Expire after hours')}*`"
        :mask="{
          alias: 'integer',
          allowMinus: false,
          placeholder: '',
          rightAlign: false,
        }"
        :error-show="$v.$invalid && $v.$dirty && $v.expire.$error"
        template
      >
        <template #append>
          <div class="w-3 text-secondary">
            <IconClock />
          </div>
        </template>
      </CInput>
    </div>
    <div class="flex items-center justify-end space-x-2">
      <CButton type="outline" accent="danger" class="text-red-100" @click="cancel">
        {{ $t('Asset requests.Cancel') }}
      </CButton>
      <CButton class="text-white" @click="activate">
        {{ $t('Asset requests.Activate') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import { IconClock } from '@/components/icons';

export default {
  name: 'CModalRequestShareBaseUpdate',
  components: {
    CButton,
    CInput,
    IconClock,
  },
  props: {
    shareableLink: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  data() {
    return {
      expire: null,
    };
  },
  validations: {
    expire: {
      required,
    },
  },
  methods: {
    ...mapActions('order', ['putOrderShareableLink']),

    cancel() {
      this.$emit('cancel');
    },

    activate() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();

      const expired = this.$moment().add(parseInt(this.expire, 10), 'h').toISOString();

      this.putOrderShareableLink({
        id: this.$route.params.id,
        code: this.shareableLink?.code,
        expired,
      }).then(() => {
        this.$emit('activate');
      });
    },
  },
};
</script>
